.donut-chart {
    width: 100%;
}

.donut-chart svg {
    height: 100%;
}

@media (min-width: 361px) {
    .donut-chart {
        width: 80%;
    }
}

@media (min-width: 525px) {
    .donut-chart {
        width: 60%;
    }
}

@media (min-width: 575px) {
    .donut-chart {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .donut-chart {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .donut-chart {
        width: 60%;
    }
}

@media (min-width: 1199px) {
    .donut-chart {
        width: 100%;
    }
}