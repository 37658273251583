:root {
  --font-size-base: 14px;
  --font-size-bigger: 16px;
  --font-size-smaller: 12px;

  --font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  --color-main: #52cea7;
  --color-second: #0A79BD;
  --color-danger: #BD090A;
  --color-grey: #f8f8f8;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
}

.color-main {
  color: var(--color-main);
}

.color-second {
  color: var(--color-second);
}

.color-danger {
  color: var(--color-danger) !important;
}

.color-grey {
  color: var(--color-grey);
}

.bg-main {
  background-color: var(--color-main);
}

.bg-second {
  background-color: var(--color-second);
}

.bg-danger {
  background-color: var(--color-danger) !important;
}

.bg-grey {
  background-color: var(--color-grey);
}

.font-bigger {
  font-size: var(--font-size-bigger);
}

.font-smaller {
  font-size: var(--font-size-smaller);
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1;
  padding: 10px 20px;
}

.btn:focus {
  box-shadow: none;
}

.color-main {
  color: #52cea7;
}

.color-second {
  color: #0A79BD;
}

.color-danger {
  color: #BD090A !important;
}

.color-grey {
  color: #f8f8f8;
}

.bg-main {
  background-color: #52cea7;
}

.bg-second {
  background-color: #0A79BD;
}

.bg-danger {
  background-color: #BD090A !important;
}

.bg-grey {
  background-color: #f8f8f8;
}

.dropdown-menu {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  max-height: 550px;
  overflow-y: auto;
  background-color: white;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: #f8f9fa;
}

@media (max-width: 330px) {
  .dropdown-toggle {
    padding: 10px !important;
  }
}

.card {
  border: none;
  border-radius: 10px;
}

.icon-box {
  background-color: var(--color-grey);
  padding: 10px;
  border-radius: 10px;
}

.icon-box img {
  height: 20px;
  width: 20px;
}

.custom-textarea {
  display: block;
  width: 100%;
  height: 150px;
  border-radius: 0.25rem;
  overflow: auto;
}

.chart-textarea {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
}

.grey-textarea {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  overflow: auto;
  background-color: var(--color-grey);
  padding: 0.5rem;
}

.card-body {
  padding: 0.75rem!important;
}

.slider-container {
  height: 150px;
  overflow: hidden;
}

.custom-arrow {
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: grey;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.next-arrow {
  right: 0px;
}

.prev-arrow {
  left: 0px;
}
